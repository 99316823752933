<ion-content class="contact-card-content">
  <ion-row class="user-details-wrapper">
    <ion-col size="4">
      <app-aspect-ratio [ratio]="{w: 1, h: 1}">
        <app-image-shell class="user-avatar"
                         animation="spinner"
                         [src]="'./assets/sample-images/notifications/100x100Notification2.jpg'"
                         [alt]="'notification image'"></app-image-shell>
      </app-aspect-ratio>
    </ion-col>
    <ion-col class="user-info-wrapper"
             size="8">
      <h4 class="user-name">Claire Hale</h4>
      <h5 class="user-handle">535 *** ** 23</h5>
    </ion-col>
  </ion-row>

  <ion-list>
    <ion-item button
              (click)="openProfileDetails()"
              detail>
      <ion-label>{{ 'MyProfile' | translate }}</ion-label>
    </ion-item>
    <ion-item button
              (click)="openChangePassword()"
              detail>
      <ion-label>{{ 'Notifications' | translate }}</ion-label>
      <ion-note slot="end"
                color="primary">99</ion-note>
    </ion-item>
    <ion-item button
              (click)="showLanguageSelector()"
              detail>
      <ion-label>{{ 'Language' | translate }}</ion-label>
      <ion-note slot="end"
                color="primary">Türkçe</ion-note>
    </ion-item>
    <!---------------------------------------------------------------------->
    <ion-list-header lines="inset">
      <ion-label>{{ 'PasswordOperations' | translate }}</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label>{{ 'RememberMe' | translate}}</ion-label>
      <ion-toggle slot="end"></ion-toggle>
    </ion-item>
    <ion-item button
              (click)="openChangePassword()"
              detail>
      <ion-label>{{ 'ChangePassword' | translate }}</ion-label>
    </ion-item>
    <!---------------------------------------------------------------------->
    <ion-list-header lines="inset">
      <ion-label>{{ 'Notifications' | translate }}</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label>{{ 'CampaignNotifications' | translate}}</ion-label>
      <ion-toggle slot="end"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>{{ 'LokationNotifications' | translate}}</ion-label>
      <ion-toggle slot="end"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>{{ 'ShowNotificationBeforeLogin' | translate}}</ion-label>
      <ion-toggle slot="end"></ion-toggle>
    </ion-item>
    <!---------------------------------------------------------------------->
  </ion-list>
  <ion-row class="logout-wrapper">
    <ion-col size="12">
      <ion-button expand="block"
                  color="danger"
                  (click)="logout()">{{ 'Logout' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-content>