import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const TTL = 60 * 60; // 1 hour;
const CACHE_PREFIX = '_cache_';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  constructor(private storage: Storage) {}

  async initStorage() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
  }

  cacheRequests(cacheKey, data) {
    const validUntil = new Date().getTime() + TTL * 1000;
    cacheKey = `${CACHE_PREFIX}${cacheKey}`;

    return this.storage.set(cacheKey, { validUntil, data });
  }

  async getCachedRequest(cacheKey) {
    const currentTime = new Date().getTime();
    cacheKey = `${CACHE_PREFIX}${cacheKey}`;

    const storedValue = await this.storage.get(cacheKey);
    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      await this.storage.remove(cacheKey);
      return null;
    } else {
      return storedValue.data;
    }
  }

  async clearCachedData() {
    const keys = await this.storage.keys();

    keys.map(async (key) => {
      if (key.startsWith(CACHE_PREFIX)) {
        await this.storage.remove(key);
      }
    });
  }

  // Example to remove one cached URL
  async invalidateCacheEntry(cacheKey) {
    cacheKey = `${CACHE_PREFIX}${cacheKey}`;
    await this.storage.remove(cacheKey);
  }
}
