import { Component, Optional } from '@angular/core';
import {
  IonRouterOutlet,
  ModalController,
  NavController,
  PickerController,
} from '@ionic/angular';
import { UserProfilePage } from '../../user/profile/user-profile.page';
import { GoogleMapComponent } from '../google-map/google-map.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', './profile.component.shell.scss'],
})
export class ProfileComponent {
  constructor(
    private pickerCtrl: PickerController,
    public modalController: ModalController
  ) {}
  openChangePassword() {
    console.log('openChangePassword');
  }

  async showLanguageSelector() {
    const pickerCtrl = await this.pickerCtrl.create({
      columns: [
        {
          name: 'myColumn',
          options: [
            {
              text: 'Türkçe',
              value: 'tr',
              selected: true,
            },
            {
              text: 'English',
              value: 'en',
              selected: false,
            },
          ],
        },
      ],
    });
    await pickerCtrl.present();
  }

  async openProfileDetails() {
    console.log(1);
    const modal = await this.modalController.create({
      component: GoogleMapComponent,
      showBackdrop: true,
      swipeToClose: true,
      presentingElement: await this.modalController.getTop(), // Get the top-most ion-modal
    });

    return await modal.present();
  }

  logout() {
    console.log('logout');
  }
}
