import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { SeoService } from './utils/seo/seo.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { CachingService } from './services/caching.service';
import { finalize } from 'rxjs/operators';
import { DealsService } from './pages/tabs/deals/deals.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss',
  ],
  providers: [DealsService],
})
export class AppComponent {
  appMenu = [
    {
      title: 'Deals',
      url: '/tab/deals',
      ionicIcon: 'albums-outline',
    },
    {
      title: 'Wallet',
      url: '/tab/user',
      ionicIcon: 'wallet-outline',
    },
    {
      title: 'Payment',
      url: '/tab/',
      ionicIcon: 'qr-code-outline',
    },
    {
      title: 'Cards',
      url: '/tab/',
      ionicIcon: 'card-outline',
    },
    {
      title: 'Profile',
      url: '/tab/profile',
      ionicIcon: 'person-outline',
    },
    {
      title: 'MyPolicies',
      url: '/tab/',
      ionicIcon: 'documents-outline',
    },
  ];
  appPages = [
    {
      title: 'Fashion',
      url: '/tab/categories/fashion',
      ionicIcon: 'list-outline',
    },
    {
      title: 'travel',
      url: '/tab/categories/travel',
      ionicIcon: 'list-outline',
    },
    {
      title: 'food',
      url: '/tab/categories/food',
      ionicIcon: 'list-outline',
    },
    {
      title: 'deals',
      url: '/tab/categories/deals',
      ionicIcon: 'list-outline',
    },
    {
      title: 'real-estate',
      url: '/tab/categories/real-estate',
      ionicIcon: 'list-outline',
    },
    {
      title: 'Profile',
      url: '/tab/user',
      ionicIcon: 'person-outline',
    },
    {
      title: 'Contact Card',
      url: '/contact-card',
      customIcon: './assets/custom-icons/side-menu/contact-card.svg',
    },
    {
      title: 'Notifications',
      url: '/tab/notifications',
      ionicIcon: 'notifications-outline',
    },
  ];
  accountPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      ionicIcon: 'log-in-outline',
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      ionicIcon: 'person-add-outline',
    },
    {
      title: 'Tutorial',
      url: '/walkthrough',
      ionicIcon: 'school-outline',
    },
    {
      title: 'Getting Started',
      url: '/getting-started',
      ionicIcon: 'rocket-outline',
    },
    {
      title: '404 page',
      url: '/page-not-found',
      ionicIcon: 'alert-circle-outline',
    },
  ];

  textDir = 'ltr';

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private seoService: SeoService,
    private cachingService: CachingService,
    private dealsService: DealsService
  ) {
    this.initializeApp();
    this.setLanguage();
  }

  async initializeApp() {
    try {
      await this.cachingService.initStorage();
      await this.dealsService.initStorage();
      await this.dealsService.getMarketingData(true);
      await SplashScreen.hide();
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }
}
