import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgFloorPipeModule } from 'angular-pipes';

import { TimeDifferencePipe } from './time-difference.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [CommonModule, IonicModule, NgFloorPipeModule],
  declarations: [TimeDifferencePipe, TimeAgoPipe, SafePipe],
  exports: [NgFloorPipeModule, TimeDifferencePipe, TimeAgoPipe, SafePipe],
})
export class PipesModule {}
