import { Injectable } from '@angular/core';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { DataStore } from '../../../shell/data-store';
import { DealsListingModel } from './listing/deals-listing.model';
import { DealsDetailsModel } from './details/deals-details.model';

import { ToastController } from '@ionic/angular';
import { Network } from '@capacitor/network';
import {
  MobileCampaignModel,
  MobileCollectionModel,
  MobileSectorModel,
} from '../../../../libs/agrio-mobile-client';
import { HomePageGroup } from './deals.models';
import { CachesService } from '@agriofinans/public-caches-client';
import { environment } from '../../../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable()
export class DealsService {
  private listingDataStore: DataStore<DealsListingModel>;
  private detailsDataStore: DataStore<DealsDetailsModel>;

  private dealsConfigStore: Storage;
  private dealsHomeStore: Storage;
  private dealsSectorsStore: Storage;
  private dealsCampaignsStore: Storage;

  connected = true;

  constructor(
    private toastController: ToastController,
    private _apiService: CachesService
  ) {
    Network.addListener('networkStatusChange', async (status) => {
      this.connected = status.connected;
    });

    this.toastController.create({ animated: false }).then((t) => {
      t.present();
      t.dismiss();
    });
  }

  async initStorage() {
    this.dealsConfigStore = new Storage({
      name: '_deals',
      storeName: '_config',
    });
    this.dealsHomeStore = new Storage({
      name: '_deals',
      storeName: '_home',
    });
    this.dealsSectorsStore = new Storage({
      name: '_deals',
      storeName: '_sectors',
    });
    this.dealsCampaignsStore = new Storage({
      name: '_deals',
      storeName: '_campaigns',
    });

    await this.dealsConfigStore.defineDriver(CordovaSQLiteDriver);
    await this.dealsHomeStore.defineDriver(CordovaSQLiteDriver);
    await this.dealsSectorsStore.defineDriver(CordovaSQLiteDriver);
    await this.dealsCampaignsStore.defineDriver(CordovaSQLiteDriver);

    await this.dealsConfigStore.create();
    await this.dealsHomeStore.create();
    await this.dealsSectorsStore.create();
    await this.dealsCampaignsStore.create();
  }

  getMarketingData(forceRefresh) {
    return this.getData();
  }

  getCampaign(id: string): Promise<MobileCampaignModel> {
    return new Promise((resolve, reject) => {
      this.dealsCampaignsStore
        .get(id)
        .then((campaign) => {
          if (campaign) {
            resolve(campaign);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getHomePageCampaigns(): Promise<HomePageGroup[]> {
    const items: HomePageGroup[] = [];
    return new Promise((resolve, reject) => {
      this.dealsHomeStore
        .forEach((v: HomePageGroup, k) => {
          items.push(v);
        })
        .then(() => {
          const retVal = items.sort((a, b) => {
            return a.order - b.order;
          });
          resolve(retVal);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private async storeHomePageCampaigns(
    layout: HomePageGroup[],
    campaigns: MobileCampaignModel[],
    sectors: MobileSectorModel[],
    collections: MobileCollectionModel[]
  ) {
    let idx = 0;
    const items = layout.map((l) => {
      switch (l.source as any) {
        case 1: //SECTOR
        case 3: //COLLECTION
        case 4: //CAMPAIGN
          return {
            id: l.id,
            order: idx++,
            title: l.title,
            type: l.type,
            source: l.source,
            imageUrl: l.imageUrl,
            items: l.items.map((i: string) => {
              const c = campaigns.find((c) => c.id == i);
              if (c) {
                return {
                  id: c.id,
                  name: c.name,
                  image: c.image,
                  target: 'campaign',
                };
              }
            }),
          } as HomePageGroup;
        case 0: //SECTORS
          return {
            id: l.id ?? 'sectors',
            order: idx++,
            title: l.title,
            type: l.type,
            source: l.source,
            imageUrl: l.imageUrl,
            items: l.items.map((i: string) => {
              const s = sectors.find((c: MobileSectorModel) => c.id == i);
              if (s) {
                return {
                  id: s.id,
                  name: s.name,
                  image: { url: s.imageUrl },
                  target: 'sector',
                };
              }
            }),
          } as HomePageGroup;
        case 2: //COLLECTIONS
          return {
            id: l.id,
            order: idx++,
            title: l.title,
            type: l.type,
            source: l.source,
            imageUrl: l.imageUrl,
            items: l.items.map((i: string) => {
              const s = collections.find(
                (c: MobileCollectionModel) => c.id == i
              );
              if (s) {
                return {
                  id: s.id,
                  name: s.name,
                  image: { url: s.imageUrl },
                  target: 'collection',
                };
              }
            }),
          } as HomePageGroup;
      }
    });
    //clear old items from storage
    (await this.dealsHomeStore.keys()).map(async (key) => {
      if (items.findIndex((i) => i.id == key) == -1) {
        await this.dealsHomeStore.remove(key);
      }
    });
    //upsert new items
    items.map(async (l) => {
      await this.dealsHomeStore.set(l.id, l);
    });
  }

  private async storeCampaigns(campaigns: MobileCampaignModel[]) {
    //clear old items from storage
    (await this.dealsCampaignsStore.keys()).map(async (key) => {
      if (campaigns.findIndex((i) => i.id == key) == -1) {
        await this.dealsCampaignsStore.remove(key);
      }
    });
    //upsert new items
    campaigns.map(async (c) => {
      await this.dealsCampaignsStore.set(c.id, c);
    });
  }

  private async getData(): Promise<any> {
    const keyMarketingDataVersion = 'marketingDataVersion';
    const version =
      (await this.dealsConfigStore.get(keyMarketingDataVersion)) ?? '';

    return new Promise((resolve, reject) => {
      this._apiService
        .getMarketingMobileCache(
          //
          environment.tenantId,
          'tr',
          version,
          'response'
        )
        .subscribe({
          next: async (response) => {
            console.log(response);
            if (response.status == 304) {
              resolve(true);
            }
            //store new data version
            await this.dealsConfigStore.set(
              keyMarketingDataVersion,
              response.body.version
            );

            const {
              layout,
              sectors,
              collections,
              campaigns,
              stores,
              brands,
            }: {
              layout: HomePageGroup[];
              sectors: MobileSectorModel[];
              collections: MobileCollectionModel[];
              campaigns: MobileCampaignModel[];
              stores;
              brands;
            } = response.body.value as any;
            await this.storeHomePageCampaigns(
              layout,
              campaigns,
              sectors,
              collections
            );
            await this.storeCampaigns(campaigns);

            resolve(true);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  // -----------------------------------------------------------------------------------------------------
}
